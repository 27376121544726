import React from 'react'
//import {useTheme} from '../components/Theming'
import Container from '../components/Container'
import {graphql} from 'gatsby'
import Layout from '../components/Layout'
import Img from "gatsby-image/withIEPolyfill"
import {css} from '@emotion/core'



const Orion = ({ data }) => {
    
    //const theme = useTheme()
  
    return(
        <Layout site={data.site}>
          <Container>
        <section css={css`
        display:flex;
        flex-wrap:wrap;
        width:100%;
       
        @media(max-width:678px) {
            flex-direction:column;
        }
        
        `}>

    <div css={css`
      width:46%;
      margin:0 2%;
     display:flex;
     flex-direction:column;
      @media(max-width:678px) {
        width: 100%;
    }
      
    `}>
     <h1 style={{margin:0,paddingTop:0,paddingBottom:20}}>
        Der blaue Orion / Mythos Schmetterling
     </h1>
     <p>Markt 1 denkt über eine Ausstellung rund um den Orion-Schmetterling alias Fetthennenbläuling nach, der als gefährdete Art seine letzten Refugien im Raum Bacharach gefunden hat. 
         Wer zu diesem Thema einen Beitrag leisten möchte, kann sich per Email bei Markt 1 melden.</p>
   
     <p>  Sie sind kenntnisreicher Laie oder Lepidopterologe/in? <br/>Dann freuen wir uns über Fotos, Ideen, Text- und/oder Bildbeiträge jeder Art. <br/>
      Da wir selbst bei Markt 1 nur ehrenamtlich aktiv sind, können wir leider Engagement von dritter Seite nicht vergüten.
</p>
<p>Ansprechpartnerin: Katrin Gloggengießer   <br/>
     <a style={{textDecoration:'underline'}} href="mailto:kontakt@markt1-bacharach.de">kontakt@markt1-bacharach.de</a>
     </p>
     </div>
     <div css={css`
      width:46%;
      display:flex;
      flex-direction:column;
      @media(max-width:678px) {
        width: 100%;
    }
      
    `}>
        <br/>
      <Img 
        fluid={data.bild.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"

        alt="Orion"
        />
 <p style={{fontSize:'.8rem',marginTop:'5px',padding:0,fontStyle:'italic',textAlign:'right'}}>Foto von Benjamin May</p>
<Img 
        fluid={data.bild2.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"

        alt="Orion"
        />
       
       
         <p style={{fontSize:'.8rem',marginTop:'5px',padding:0,fontStyle:'italic',textAlign:'right'}}>Foto C.T. Bingham - Fauna of British India - Butterflies / Wikipedia</p>
<Img 
        fluid={data.bild3.childImageSharp.fluid}
        objectFit="cover"
        objectPosition="50% 50%"

        alt="Orion"
        />        
        <p style={{fontSize:'.8rem',marginTop:'5',padding:0,fontStyle:'italic',textAlign:'right'}}>Foto http://remf.dartmouth.edu / Wikipedia</p>

        </div>

     
 
     
     </section>
     </Container>
        </Layout>

    )
}

export default Orion

export const orionQuery = graphql`

query {
    site {
      ...site
      siteMetadata {
        title
      }
    }
        bild: file(relativePath: { eq: "schm.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
    
        bild2: file(relativePath: { eq: "zunge.jpeg" }) {
            childImageSharp {
            
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bild3: file(relativePath: { eq: "antennen.png" }) {
            childImageSharp {
            
              fluid(maxWidth: 1100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
  
}

`

